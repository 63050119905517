export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  blogs: '/blogs',
  features: '/features',
  scheduleademo: '/schedule-a-demo',
  page404: '/404',
  page500: '/500',
  components: '/components',
  termsCondition: '/terms-conditions',
  privacy: '/privacy',
  blogDetails: '/blog-details',
};
