import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Link, Divider, Container, Typography, Stack, Button, Box } from '@mui/material';
// routes
import { PATH_PAGE } from '../../routes/paths';
// components
import BuissMasterLogo from '../../components/BuissMasterLogo';
import Logo from '../../components/Logo';
import SocialsButton from '../../components/SocialsButton';
import CustomButton from '../../components/CustomButton';

// ----------------------------------------------------------------------

const LINKS = [
  {
    headline: 'BuissMaster OMS',
    children: [
      { name: 'Home', href: '/' },
      { name: 'About us', href: PATH_PAGE.about },
      { name: 'Features', href: PATH_PAGE.features },
      { name: 'Pricing', href: PATH_PAGE.pricing },
      // { name: 'Blogs', href: PATH_PAGE.blogs },
      { name: 'Contact us', href: PATH_PAGE.contact },
      {
        name: 'Terms and Condition',
        href: PATH_PAGE.termsCondition,
      },
      {
        name: 'Privacy Policy',
        href: PATH_PAGE.privacy,
      },
    ],
  },

  {
    headline: 'Features',
    children: [
      { name: 'Order Management System', href: PATH_PAGE.features },
      { name: 'Inventory Management System', href: PATH_PAGE.features },
      { name: 'Payment Reconciliation', href: PATH_PAGE.features },
      { name: 'Return Reconciliation', href: PATH_PAGE.features },
      { name: 'Cancel Reconciliation', href: PATH_PAGE.features },
    ],
  },

  {
    headline: 'Contact',
    children: [
      { name: 'contact@buissmaster.com', href: 'mailto:contact@buissmaster.com', isBlank: true },
      { name: '+91 261-3549130', href: 'tel:+91 261-3549130', isBlank: true },
      {
        isBlank: true,
        name: '314, Varni Plaza, Sudama Chowk,',
        href: 'https://www.google.com/maps/dir/21.2378788,72.8633633/buissmaster/@21.2351749,72.8625208,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be04f48cd479e1f:0x23bc2b3c573001d0!2m2!1d72.8797259!2d21.2381053',
      },
      {
        isBlank: true,
        name: 'Mota Varachha, Surat, Gujrat - 394101',
        href: 'https://www.google.com/maps/dir/21.2378788,72.8633633/buissmaster/@21.2351749,72.8625208,15z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x3be04f48cd479e1f:0x23bc2b3c573001d0!2m2!1d72.8797259!2d21.2381053',
      },
    ],
  },
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default,
}));

const LinkStyle = styled('a')(({ theme }) => ({
  lineHeight: '1.5714285714285714',
  fontSize: '0.875rem',
  fontFamily: 'Public Sans,sans-serif',
  fontWeight: 400,
  color: 'inherit',
  textDecoration: 'none',
  display: 'block',
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} md={3} sx={{ mb: 3 }}>
            <Box sx={{ display: 'flex', justifyContent: { xs: 'center', md: 'flex-start' } }}>
              <Logo />
            </Box>
            <Typography variant="body2" sx={{ pr: { md: 5 }, mt: 5 }}>
              Your privacy is our responsibility. We believe that trust is paramount in a relationship. We do not own or
              sell your data.
            </Typography>

            <Stack
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              <SocialsButton sx={{ mx: 0.5 }} />
            </Stack>
          </Grid>

          <Grid item xs={12} md={7}>
            <Stack spacing={5} direction={{ xs: 'column', md: 'row' }} justifyContent="space-between">
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline">
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => (
                    <LinkStyle
                      href={link.href}
                      key={link.name}
                      color="inherit"
                      variant="body2"
                      target={link.isBlank && '_blank'}
                      rel={link.isBlank && 'noreferrer'}
                    >
                      {link.name}
                    </LinkStyle>
                  ))}
                </Stack>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={1}>
            <Stack spacing={2} justifyContent="space-between">
              <CustomButton buttonText="Log in" href="https://app.buissmaster.com/auth/login" isBlank />
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 3,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center' },
          }}
        >
          © Copyright 2022. All Rights Reserved By BuissMaster OMS
        </Typography>
      </Container>
    </RootStyle>
  );
}
