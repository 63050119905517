// routes
import {Button} from "@mui/material";
import { PATH_PAGE } from '../../routes/paths';
// components
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = [
  {
    title: 'Home',
    icon: <Iconify icon={'mingcute:home-4-fill'} {...ICON_SIZE} />,
    path: '/',
  },

  {
    title: 'About us',
    icon: <Iconify icon={'mdi:account-question'} {...ICON_SIZE} />,
    path: PATH_PAGE.about,
  },

  {
    title: 'Features',
    icon: <Iconify icon={'teenyicons:star-circle-solid'} {...ICON_SIZE} />,
    path: PATH_PAGE.features,
  },

  {
    title: 'Packages',
    icon: <Iconify icon={'fluent:payment-32-filled'} {...ICON_SIZE} />,
    path: PATH_PAGE.pricing,
  },
  // {
  //   title: 'Blogs',
  //   icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
  //   path: PATH_PAGE.blogs,
  // },

  {
    title: 'Contact us',
    icon: <Iconify icon={'mdi:telephone-in-talk'} {...ICON_SIZE} />,
    path: PATH_PAGE.contact,
  },
];

export default menuConfig;
