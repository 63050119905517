import { Button } from '@mui/material';
import React from 'react';

const CustomButton = ({ variant = 'outlined', buttonText, href, isBlank = false, startIcon, endIcon, sx, onClick }) => {
  return (
    <Button
      size="large"
      variant={variant}
      {...(isBlank && {
        target: '_blank',
      })}
      rel="noopener"
      href={href}
      className="hvr-sweep-to-right"
      sx={{ height: 'auto', ...sx }}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
    >
      {buttonText}
    </Button>
  );
};

export default CustomButton;
